import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";

import { deleteIcon, minusIcon, plusIcon } from "src/assets/step33dViwer";

import { applyVisibility } from "./PanelGridPlacement";
const raycaster = new THREE.Raycaster();

export default class PanelGrid extends THREE.Object3D {
  bounds = new THREE.Box3();
  size = new THREE.Vector3();
  selectionSize = new THREE.Vector3();
  /*
  static getFont(){
    // wut rikin?
    this.fontLoader = new FontLoader();
    // Load the font and store it in this.font
    this.fontLoader.load(
      "https://threejsfundamentals.org/threejs/resources/threejs/fonts/helvetiker_regular.typeface.json",
      (font) => {
        this.font = font;
      }
    );
  } */
  
  constructor({ modules, deleteObject, plusObject, dimentions, documentRef }) {
    super();
    // debugger
    this.modules = modules.slice(0);
    this.selectionMesh = planeMesh.clone();
    this.add(this.selectionMesh);
    // this.defaultOffset = { x: 1, y: 1 };
    this.deleteObject = deleteObject;
    this.plusObject = plusObject;
    this.font = null;
    this.index = 1;
    this.dimentions = dimentions;
    this.documentRef = documentRef;
    // this.computePanelSize();
    // this.defaultPanelSize= defaultPanelSize
  }

  // computePanelSize(){
  //   const box = new THREE.Box3();
  //   box.setFromObject(this.modules[0]);
  //   const sz = box.getSize(new THREE.Vector3());
  //   // debugger
  //   this.defaultPanelSize.set(sz.x,sz.y,sz.z);
  // }

/*
  setSize(x, y, z) {
    debugger
    this.size.set(x, y, z);
  }

  calculateCenterPoint() {
    const center = new THREE.Vector3();
    this.selectionMesh.geometry.getCenter(center);
    debugger
    this.add(center);
    return center;
  }
  */
  
 
 defaultPanelSize = new THREE.Vector3(1.5,2,.01)

 setSize(x, y, z) {
  this.size.set(x, y, z);
  }

 
  recomputeOccupancy({ setup, angle, gridFaceUp, targets }) {
   const s = this.size.clone().multiply(this.scale);
   
    // this.computePanelSize();
    const panelX = this.dimentions.height ||  this.defaultPanelSize.x;
    const panelY = this.dimentions.width ||  this.defaultPanelSize.y;

    this.occupancy = PanelGrid.raycastGrid({
      setup,
      angle,
      gridFaceUp,
      root: this.children[0],
      targets,
      step: {
        x: panelX / s.x,
        y: panelY / -s.y,
      },
      // offset: this.defaultOffset,
    });
    return this.occupancy;
  }

  static raycastGrid({ setup, gridFaceUp, root, targets, step }) {
    const down = new THREE.Vector3(0, -1, 0);
    let results = [];
    let rows = 0;
    let cols;
    const panelSpacing = 0.005;
    const panelMatrix = [];

    const nx = (1 / step.x) | 0;
    const ny = (1 / step.y) | 0;
    const margx = (1 - nx * step.x) * 0.5;
    const margy = (1 - ny * step.y) * 0.5;

    for (let x = step.x * 0.5 + margx; x <= 1 - step.x * 0.5; x += step.x + panelSpacing) {
      panelMatrix.push([]);
      cols = 0;
      for (let z = step.y * 0.5 + margy; z <= 1 - step.y * 0.5; z += step.y + panelSpacing) {
        const origin = new THREE.Vector3(gridFaceUp > 0 ? x + step.x * 0.5 : x - step.x * 0.5, z + step.y * 0.5, 1.1);
        root && root.localToWorld(origin);
        raycaster.set(origin, down);

        const res = {
          origin,
          object: targets[0],
        };
        results.push(res);

        const intersects = raycaster.intersectObjects(targets, true);
        if (intersects.length > 0) {
          res.point = intersects[0].point.clone();
          res.normal = intersects[0].normal.clone();
          res.object = intersects[0].object;

          const validPlanarThresholdWS = 1;
          if (res.origin.distanceTo(res.point) < validPlanarThresholdWS) res.isSupported = true;
        }
        panelMatrix[rows].push({obstructed: false, enabled: true});
        cols++;
      }
      rows++;
    }

    if (setup === "tent" && cols  % 2 !== 0) {
      results = results.filter((_, index) => (index + 1) % cols !== 0);
      cols--
      panelMatrix.forEach((row) => {
        row.pop();
      });
    }

    return {
      results,
      rows,
      cols,
      root,
      panelMatrix
    };
  }

  disposeOccupancy() {
    if (!this.occupancy) return;
    const occupancy = this.occupancy;
    occupancy.results.forEach((res) => {
      res && res.indicator && res.indicator.parent.remove(res.indicator);
    });
    occupancy.results.length = 0;
  }

  redrawOccupancy(setup, angle) {
    const occupancy = this.occupancy;
    const results = occupancy.results;
    const loader = new THREE.TextureLoader();
    const plusTexture = loader.load(plusIcon);
    const minusTexture = loader.load(minusIcon);
    this.index = 1;
    // let normal = v1.set(0, 0, 1).applyQuaternion(occupancy.root.quaternion);
    const grid = this.documentRef.current.panelGrids.find((grid) => grid.children[0].uuid === this.occupancy.root.uuid);
    for (const [index, result] of results.entries()) {
      if (!result) continue;
      if (!result.object.isMesh) continue;

      const { boundsTree } = result.object.geometry;
      const module = this.modules[0].clone();
      const panel = new THREE.Object3D();
      panel.position.copy(result.origin);
      panel.rotation.setFromQuaternion(occupancy.root.parent.quaternion);
      panel.updateMatrix();
      result.object.attach(panel);
      panel.updateMatrixWorld();

      if(grid) {
        const position = this.getPosition(grid.panelMatrix, index);
        panel.isNotVisible = !grid.panelMatrix[position.row][position.col].enabled;
      }

      const moduleIsObstructed = (module) => {
        const box = new THREE.Box3().setFromObject(module);
        return boundsTree.intersectsBox(box, panel.matrix);
      };

      const moduleIsSupported = (module) => {
        const box = new THREE.Box3().setFromObject(module);
        const panelThickness = box.max.z - box.min.z;
        const supportThickness = panelThickness;
        const supportLength = panelThickness * 20;
        const c0 = box.clone();
        c0.max.x = c0.min.x + supportThickness;
        c0.max.y = c0.min.y + supportThickness;
        c0.min.z = c0.max.z - supportLength;
        if (!boundsTree.intersectsBox(c0, panel.matrix)) return false;

        const c1 = box.clone();
        c1.min.x = c1.max.x - supportThickness;
        c1.max.y = c1.min.y + supportThickness;
        c1.min.z = c1.max.z - supportLength;
        if (!boundsTree.intersectsBox(c1, panel.matrix)) return false;

        const c2 = box.clone();
        c2.min.x = c2.max.x - supportThickness;
        c2.min.y = c2.max.y - supportThickness;
        c2.min.z = c2.max.z - supportLength;
        if (!boundsTree.intersectsBox(c2, panel.matrix)) return false;

        const c3 = box.clone();
        c3.max.x = c3.min.x + supportThickness;
        c3.min.y = c3.max.y - supportThickness;
        c3.min.z = c3.max.z - supportLength;
        if (!boundsTree.intersectsBox(c3, panel.matrix)) return false;
        return true;
      };
      const icons = [];
      const panelPosition = this.getPosition(occupancy.panelMatrix, index);
      if (!result.isSupported || moduleIsObstructed(module) || !moduleIsSupported(module)) {

        if(setup === "tent" && panelPosition.col % 2 !== 0) {
          occupancy.panelMatrix[panelPosition.row][panelPosition.col - 1].obstructed = true;
        }
        
        occupancy.panelMatrix[panelPosition.row][panelPosition.col].obstructed = true;

        /* for (let i = 1; i < this.modules.length; i++) {
          const submodule = this.modules[i].clone();
          if (!moduleIsObstructed(submodule) && moduleIsSupported(module)) panel.add(submodule);
        } */
      } else {
        module.children[0].originalMaterial = module.children[0].material;
        

        function degreesToRadians(degrees) {
          return degrees * (Math.PI / 180);
        }

        switch(setup) {
          case "tent":
            if(panelPosition.col % 2 === 0) {
              if(angle < 15) {
                module.position.y = module.position.y - module.scale.y * 0.01;
                module.position.z = module.position.z + module.scale.y * 0.25;
              }else if(angle < 20) {
                module.position.y = module.position.y - module.scale.y * 0.03;
                module.position.z = module.position.z + module.scale.y * 0.34;
              }else if(angle < 25) {
                module.position.y = module.position.y - module.scale.y * 0.05;
                module.position.z = module.position.z + module.scale.y * 0.43;
              }else if(angle < 30) {
                module.position.y = module.position.y - module.scale.y * 0.09;
                module.position.z = module.position.z + module.scale.y * 0.52;
              }else if(angle < 35) {
                module.position.y = module.position.y - module.scale.y * 0.14;
                module.position.z = module.position.z + module.scale.y * 0.61;
              }else if(angle < 40) {
                module.position.y = module.position.y - module.scale.y * 0.19;
                module.position.z = module.position.z + module.scale.y * 0.69;
              }else if(angle < 45) {
                module.position.y = module.position.y - module.scale.y * 0.25;
                module.position.z = module.position.z + module.scale.y * 0.77;
              }else if(angle < 50) {
                module.position.y = module.position.y - module.scale.y * 0.31;
                module.position.z = module.position.z + module.scale.y * 0.84;
              }else if(angle < 55) {
                module.position.y = module.position.y - module.scale.y * 0.38;
                module.position.z = module.position.z + module.scale.y * 0.90;
              }else if(angle < 60) {
                module.position.y = module.position.y - module.scale.y * 0.46;
                module.position.z = module.position.z + module.scale.y * 0.96;
              }else if(angle < 65) {
                module.position.y = module.position.y - module.scale.y * 0.54;
                module.position.z = module.position.z + module.scale.y * 1.01;
              }else {
                module.position.y = module.position.y - module.scale.y * 0.63;
                module.position.z = module.position.z + module.scale.y * 1.05;
              }
              module.rotation.x = module.rotation.x - degreesToRadians(angle);
            }else {
              module.rotation.x = module.rotation.x + degreesToRadians(angle);
            }
            break;
          case "parallel":
            module.rotation.x = module.rotation.x + degreesToRadians(angle);
            break;
          default:
        } 

        panel.add(module);
        panel.name = "supported";
        const boxModule = new THREE.Box3().setFromObject(module);
        const deleteIcon = this.deleteObject.clone(); // changes
        deleteIcon.name = "deleteIcon"; // changes
        const box = new THREE.Box3().setFromObject(panel); // changes
        const defaultPanelSize = new THREE.Vector3();
        box.getSize(defaultPanelSize); // changes

        const panelWidth = boxModule.max.x - boxModule.min.x;
        const panelHeight = boxModule.max.y - boxModule.min.y;

        deleteIcon.position.set(
          -panelWidth / 2 , 
          panelHeight / 2 + 0.1,
          0.4,
        );
        deleteIcon.visible = false;
        deleteIcon.raycast = () => {};
        panel.add(deleteIcon); // changes

        const greenMesh = this.plusObject.clone();
        greenMesh.rotation.x = -Math.PI / 2; // Rotate horizontally flat
        greenMesh.rotation.y = Math.PI / 2;
        greenMesh.position.set(
          -panelWidth / 2 - 0.45 , 
          panelHeight / 2 ,
           0.4);
        greenMesh.visible = false; // Make it invisible by default
        greenMesh.name = "add-icon";
        greenMesh.raycast = () => {};
        panel.add(greenMesh);

        if (minusTexture) {
          const minusMaterial = new THREE.SpriteMaterial({ map: minusTexture, color: 0xffffff });
          const minusIcon = new THREE.Sprite(minusMaterial);
          minusIcon.rotation.x = -Math.PI / 2; // Rotate horizontally flat
          minusIcon.rotation.y = Math.PI / 2;
      

          // Calculate width and height from the bounding box
          const panelWidth = boxModule.max.x - boxModule.min.x;
          const panelHeight = boxModule.max.y - boxModule.min.y; // For a horizontally aligned panel

          // Position the icon at the top-left corner of the panel
          minusIcon.position.set(-panelWidth / 2 - 0.45, panelHeight / 2 + 0.45, 0.3);
          minusIcon.scale.set(0.35, 0.35, 0.35);
          minusIcon.visible = false;
          minusIcon.name = "minus-icon";
          minusIcon.raycast = () => {};
          panel.add(minusIcon);
        }

        if (plusTexture) {
          const plusMaterial = new THREE.SpriteMaterial({ map: plusTexture, color: 0xf4c341 });
          const plusIcon = new THREE.Sprite(plusMaterial);
          plusIcon.rotation.x = -Math.PI / 2; // Rotate horizontally flat
          plusIcon.rotation.y = Math.PI / 2;
          // const panelWidth = 1; // Replace with actual panel width
          // const panelHeight = 1; // Replace with actual panel height
          // Calculate width and height from the bounding box
   

          // Position the icon at the top-left corner of the panel
          plusIcon.position.set(0,0 , 0.3);
          plusIcon.scale.set(0.4, 0.4, 0.4);
          plusIcon.visible = false;
          plusIcon.name = "plus-icon";
          panel.add(plusIcon);
        }

        function createTextSprite(text, parameters = {}) {
          const fontface = parameters.fontface || "Arial";
          const fontsize = parameters.fontsize || 24;
          const color = parameters.color || { r: 255, g: 255, b: 255, a: 1.0 };

          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          context.font = `${fontsize}px ${fontface}`;

          // Set text color
          context.fillStyle = `rgba(${color.r},${color.g},${color.b},${color.a})`;
          context.fillText(text, 0, fontsize);

          const texture = new THREE.CanvasTexture(canvas);
          texture.needsUpdate = true;

          const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
          const sprite = new THREE.Sprite(spriteMaterial);

          sprite.scale.set(1, 0.5, 1); // Adjust scale as needed
          sprite.visible = false;

          return sprite;
        }

        // Usage in your existing code
        const numberText = createTextSprite("1", {
          fontsize: 72,
          color: { r: 8, g: 218, b: 145, a: 1 },
        });

        // const panelWidth = boxModule.max.x - boxModule.min.x;
        // const panelHeight = boxModule.max.y - boxModule.min.y; // For a horizontally aligned panel

        // Position the text at the bottom left corner of the panel
        numberText.position.set(-panelWidth / 2 - 0.2, -panelHeight / 2 + 0.5, 0.3);

        numberText.raycast = () => {};
        panel.add(numberText);
      }

      this.attach(panel);

      result.indicator = panel;

      panel.updateMatrixWorld(true);

      /*
        let lookTarg = deleteIcon.position.clone();
        lookTarg.z+=1;
        deleteIcon.localToWorld(lookTarg);
        deleteIcon.lookAt(lookTarg);

        */
      icons.forEach((icon) => {
        const lookTarg = new THREE.Vector3(0, 0, 1);
        lookTarg.applyMatrix4(icon.matrixWorld);
        icon.lookAt(lookTarg);
      });

      panel.traverse((e) => (e.matrixAutoUpdate = false));

      if(grid) {
        applyVisibility(grid);
      };
    }
    return(this.occupancy);
  }

  getPosition = (matrix, index) => {
    let row = 0;
    while (index >= matrix[row].length) {
      index -= matrix[row].length;
      row++;
    }
    const col = index;
    return({row, col});
  };
}

const baseMetal = 0.0;
const baseRough = 0.8;
PanelGrid.selectedStateMaterial = new THREE.MeshStandardMaterial({
  metalness: baseMetal,
  roughness: baseRough,
  transparent: true,
  opacity: 0.4,
  depthWrite: false,
  color: "teal",
});
PanelGrid.collidedStateMaterial = new THREE.MeshStandardMaterial({
  metalness: baseMetal,
  roughness: baseRough,
  transparent: true,
  depthWrite: false,
  opacity: 0.15,
  color: "red",
});
PanelGrid.nonCollidedStateMaterial = new THREE.MeshStandardMaterial({
  metalness: baseMetal,
  roughness: baseRough,
  transparent: true,
  depthWrite: false,
  opacity: 0.9,
  color: "green",
});
PanelGrid.deselectedStateMaterial = new THREE.MeshStandardMaterial({
  metalness: baseMetal,
  roughness: baseRough,
  transparent: true,
  depthWrite: false,
  opacity: 0.1,
  color: "blue",
});
const planeMesh = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), PanelGrid.selectedStateMaterial);
planeMesh.geometry.translate(0.5, 0.5, 0.5);
planeMesh.geometry.computeBoundingBox();
