import axios from "axios";
import {
	Quaternion,
  Euler
} from "three";

export async function solarProductionData(panelGrids, quoteDetails, solargisData) {
  const RAD2DEG = 180 / Math.PI;

  const getPanelData = () => {
    const pd = [];
    panelGrids.forEach((pg) => {
      const panelData = pg.occupancy.results[0];
      // console.log(panelData?.indicator, "panelData.indicator");
      if (panelData?.indicator) {
        const worldQuaternion = new Quaternion();

        if(pg.setup !== "tent") {
          if(pg.children.filter((ele) => ele.name === "supported").length > 0 && pg.children.filter((ele) => ele.name === "supported")[0].children.length > 0) {;
            pg.children.filter((ele) => ele.name === "supported")[0].children[0].getWorldQuaternion(worldQuaternion);

            let tilt = RAD2DEG * new Euler().setFromQuaternion(worldQuaternion).x;
            let azimuth = RAD2DEG * new Euler().setFromQuaternion(worldQuaternion).y;
    
            // Normalize tilt to [0, 90]
            tilt = Math.abs(tilt % 360);
            if (tilt > 90) {
              tilt = 180 - tilt;
            }
    
            // Normalize azimuth to [-180, 180]
            azimuth = ((azimuth + 180) % 360) - 180;
    
            // console.log(pg.children, "3758+++");
            pd.push({
              type: panelData.object.name,
              moduleQuantity: pg.children
              .slice(1)
              .filter((ele) => ele.name === "supported" && !ele?.isNotVisible)?.length,
              orientation: new Euler().setFromQuaternion(worldQuaternion).clone(),
              tilt,
              azimuth,
            });
          }
        }else {
          if(pg.children.filter((ele) => ele.name === "supported").length > 1 && pg.children.filter((ele) => ele.name === "supported")[0].children.length > 0 && pg.children.filter((ele) => ele.name === "supported")[1].children.length > 0) {
            pg.children.filter((ele) => ele.name === "supported")[0].children[0].getWorldQuaternion(worldQuaternion)
            let tilt = RAD2DEG * new Euler().setFromQuaternion(worldQuaternion).x;
            let azimuth = RAD2DEG * new Euler().setFromQuaternion(worldQuaternion).y;
            pg.children.filter((ele) => ele.name === "supported")[1].children[0].getWorldQuaternion(worldQuaternion)
            let tiltOpposite = RAD2DEG * new Euler().setFromQuaternion(worldQuaternion).x;
            let azimuthOpposite = RAD2DEG * new Euler().setFromQuaternion(worldQuaternion).y;
  
            // Normalize tilt to [0, 90]
            tilt = Math.abs(tilt % 360);
            if (tilt > 90) {
              tilt = 180 - tilt;
            }
            tiltOpposite = Math.abs(tiltOpposite % 360);
            if (tiltOpposite > 90) {
              tiltOpposite = 180 - tiltOpposite;
            }
  
            // Normalize azimuth to [-180, 180]
            azimuth = ((azimuth + 180) % 360) - 180;
            azimuthOpposite = ((azimuthOpposite + 180) % 360) - 180;
  
            // console.log(pg.children, "3758+++");
            pd.push({
              type: panelData.object.name,
              moduleQuantity: Math.floor(pg.children
                .slice(1)
                .filter((ele) => ele.name === "supported" && !ele?.isNotVisible)?.length / 2),
              orientation: new Euler().setFromQuaternion(worldQuaternion).clone(),
              tilt,
              azimuth,
              tent:true
            });
            pd.push({
              type: panelData.object.name,
              moduleQuantity: Math.floor(pg.children
                .slice(1)
                .filter((ele) => ele.name === "supported" && !ele?.isNotVisible)?.length) - Math.floor(pg.children
                  .slice(1)
                  .filter((ele) => ele.name === "supported" && !ele?.isNotVisible)?.length / 2),
              orientation: new Euler().setFromQuaternion(worldQuaternion).clone(),
              tilt: tiltOpposite,
              azimuth: azimuthOpposite,
              tent: true
            });
          }
        }
      }
    });
    return pd;
  };

  const data = getPanelData();

  const promises = data.map(async (e) => {
    const url = `https://staging.backend-v2.solarhub24.de/api/comman/pvcalc?lat=${quoteDetails.lat}&lon=${quoteDetails?.long}&peakpower=0.408&loss=2.5&aspect=${e.azimuth}&angle=${e.tilt}&outputformat=json&pvtechchoice=crystSi`;

    try {
      const response = await axios.get(url);
      return { outputs: response.data.outputs, details: e};
    } catch (error) {
      console.error("Error making the API call:", error);
      return null;
    }
  });

  solargisData = await Promise.all(promises);

  let simpleTent = false;
  const finalSolargisData = [];

  solargisData.map((data, index) => {
    if(simpleTent) {
      simpleTent = false;
    }else {
      if(data.details.tent) {
        simpleTent = true;
      }

      if(data.details.tent) {
        const getMidpoint = (a, b) => (a + b) / 2;
        const newData = {
          details: {
            azimuth: data.details.azimuth,
            moduleQuantity: data.details.moduleQuantity + solargisData[index + 1].details.moduleQuantity,
            orientation: data.details.orientation,
            tent: data.details.tent,
            tilt: data.details.tilt,
            type: data.details.type
          },
          outputs: {
            monthly: {
              fixed: [
                {
                  E_d: getMidpoint(data.outputs.monthly.fixed[0].E_d, solargisData[index + 1].outputs.monthly.fixed[0].E_d),
                  E_m: getMidpoint(data.outputs.monthly.fixed[0].E_m, solargisData[index + 1].outputs.monthly.fixed[0].E_m),
                  "H(i)_d": getMidpoint(data.outputs.monthly.fixed[0]["H(i)_d"], solargisData[index + 1].outputs.monthly.fixed[0]["H(i)_d"]),
                  "H(i)_m": getMidpoint(data.outputs.monthly.fixed[0]["H(i)_m"], solargisData[index + 1].outputs.monthly.fixed[0]["H(i)_m"]),
                  SD_m: getMidpoint(data.outputs.monthly.fixed[0].SD_m, solargisData[index + 1].outputs.monthly.fixed[0].SD_m),
                  month: data.outputs.monthly.fixed[0].month
                },
                {
                  E_d: getMidpoint(data.outputs.monthly.fixed[1].E_d, solargisData[index + 1].outputs.monthly.fixed[1].E_d),
                  E_m: getMidpoint(data.outputs.monthly.fixed[1].E_m, solargisData[index + 1].outputs.monthly.fixed[1].E_m),
                  "H(i)_d": getMidpoint(data.outputs.monthly.fixed[1]["H(i)_d"], solargisData[index + 1].outputs.monthly.fixed[1]["H(i)_d"]),
                  "H(i)_m": getMidpoint(data.outputs.monthly.fixed[1]["H(i)_m"], solargisData[index + 1].outputs.monthly.fixed[1]["H(i)_m"]),
                  SD_m: getMidpoint(data.outputs.monthly.fixed[1].SD_m, solargisData[index + 1].outputs.monthly.fixed[1].SD_m),
                  month: data.outputs.monthly.fixed[1].month
                },
                {
                  E_d: getMidpoint(data.outputs.monthly.fixed[2].E_d, solargisData[index + 1].outputs.monthly.fixed[2].E_d),
                  E_m: getMidpoint(data.outputs.monthly.fixed[2].E_m, solargisData[index + 1].outputs.monthly.fixed[2].E_m),
                  "H(i)_d": getMidpoint(data.outputs.monthly.fixed[2]["H(i)_d"], solargisData[index + 1].outputs.monthly.fixed[2]["H(i)_d"]),
                  "H(i)_m": getMidpoint(data.outputs.monthly.fixed[2]["H(i)_m"], solargisData[index + 1].outputs.monthly.fixed[2]["H(i)_m"]),
                  SD_m: getMidpoint(data.outputs.monthly.fixed[2].SD_m, solargisData[index + 1].outputs.monthly.fixed[2].SD_m),
                  month: data.outputs.monthly.fixed[2].month
                },
                {
                  E_d: getMidpoint(data.outputs.monthly.fixed[3].E_d, solargisData[index + 1].outputs.monthly.fixed[3].E_d),
                  E_m: getMidpoint(data.outputs.monthly.fixed[3].E_m, solargisData[index + 1].outputs.monthly.fixed[3].E_m),
                  "H(i)_d": getMidpoint(data.outputs.monthly.fixed[3]["H(i)_d"], solargisData[index + 1].outputs.monthly.fixed[3]["H(i)_d"]),
                  "H(i)_m": getMidpoint(data.outputs.monthly.fixed[3]["H(i)_m"], solargisData[index + 1].outputs.monthly.fixed[3]["H(i)_m"]),
                  SD_m: getMidpoint(data.outputs.monthly.fixed[3].SD_m, solargisData[index + 1].outputs.monthly.fixed[3].SD_m),
                  month: data.outputs.monthly.fixed[3].month
                },
                {
                  E_d: getMidpoint(data.outputs.monthly.fixed[4].E_d, solargisData[index + 1].outputs.monthly.fixed[4].E_d),
                  E_m: getMidpoint(data.outputs.monthly.fixed[4].E_m, solargisData[index + 1].outputs.monthly.fixed[4].E_m),
                  "H(i)_d": getMidpoint(data.outputs.monthly.fixed[4]["H(i)_d"], solargisData[index + 1].outputs.monthly.fixed[4]["H(i)_d"]),
                  "H(i)_m": getMidpoint(data.outputs.monthly.fixed[4]["H(i)_m"], solargisData[index + 1].outputs.monthly.fixed[4]["H(i)_m"]),
                  SD_m: getMidpoint(data.outputs.monthly.fixed[4].SD_m, solargisData[index + 1].outputs.monthly.fixed[4].SD_m),
                  month: data.outputs.monthly.fixed[4].month
                },
                {
                  E_d: getMidpoint(data.outputs.monthly.fixed[5].E_d, solargisData[index + 1].outputs.monthly.fixed[5].E_d),
                  E_m: getMidpoint(data.outputs.monthly.fixed[5].E_m, solargisData[index + 1].outputs.monthly.fixed[5].E_m),
                  "H(i)_d": getMidpoint(data.outputs.monthly.fixed[5]["H(i)_d"], solargisData[index + 1].outputs.monthly.fixed[5]["H(i)_d"]),
                  "H(i)_m": getMidpoint(data.outputs.monthly.fixed[5]["H(i)_m"], solargisData[index + 1].outputs.monthly.fixed[5]["H(i)_m"]),
                  SD_m: getMidpoint(data.outputs.monthly.fixed[5].SD_m, solargisData[index + 1].outputs.monthly.fixed[5].SD_m),
                  month: data.outputs.monthly.fixed[5].month
                },
                {
                  E_d: getMidpoint(data.outputs.monthly.fixed[6].E_d, solargisData[index + 1].outputs.monthly.fixed[6].E_d),
                  E_m: getMidpoint(data.outputs.monthly.fixed[6].E_m, solargisData[index + 1].outputs.monthly.fixed[6].E_m),
                  "H(i)_d": getMidpoint(data.outputs.monthly.fixed[6]["H(i)_d"], solargisData[index + 1].outputs.monthly.fixed[6]["H(i)_d"]),
                  "H(i)_m": getMidpoint(data.outputs.monthly.fixed[6]["H(i)_m"], solargisData[index + 1].outputs.monthly.fixed[6]["H(i)_m"]),
                  SD_m: getMidpoint(data.outputs.monthly.fixed[6].SD_m, solargisData[index + 1].outputs.monthly.fixed[6].SD_m),
                  month: data.outputs.monthly.fixed[6].month
                },
                {
                  E_d: getMidpoint(data.outputs.monthly.fixed[7].E_d, solargisData[index + 1].outputs.monthly.fixed[7].E_d),
                  E_m: getMidpoint(data.outputs.monthly.fixed[7].E_m, solargisData[index + 1].outputs.monthly.fixed[7].E_m),
                  "H(i)_d": getMidpoint(data.outputs.monthly.fixed[7]["H(i)_d"], solargisData[index + 1].outputs.monthly.fixed[7]["H(i)_d"]),
                  "H(i)_m": getMidpoint(data.outputs.monthly.fixed[7]["H(i)_m"], solargisData[index + 1].outputs.monthly.fixed[7]["H(i)_m"]),
                  SD_m: getMidpoint(data.outputs.monthly.fixed[7].SD_m, solargisData[index + 1].outputs.monthly.fixed[7].SD_m),
                  month: data.outputs.monthly.fixed[7].month
                },
                {
                  E_d: getMidpoint(data.outputs.monthly.fixed[8].E_d, solargisData[index + 1].outputs.monthly.fixed[8].E_d),
                  E_m: getMidpoint(data.outputs.monthly.fixed[8].E_m, solargisData[index + 1].outputs.monthly.fixed[8].E_m),
                  "H(i)_d": getMidpoint(data.outputs.monthly.fixed[8]["H(i)_d"], solargisData[index + 1].outputs.monthly.fixed[8]["H(i)_d"]),
                  "H(i)_m": getMidpoint(data.outputs.monthly.fixed[8]["H(i)_m"], solargisData[index + 1].outputs.monthly.fixed[8]["H(i)_m"]),
                  SD_m: getMidpoint(data.outputs.monthly.fixed[8].SD_m, solargisData[index + 1].outputs.monthly.fixed[8].SD_m),
                  month: data.outputs.monthly.fixed[8].month
                },
                {
                  E_d: getMidpoint(data.outputs.monthly.fixed[9].E_d, solargisData[index + 1].outputs.monthly.fixed[9].E_d),
                  E_m: getMidpoint(data.outputs.monthly.fixed[9].E_m, solargisData[index + 1].outputs.monthly.fixed[9].E_m),
                  "H(i)_d": getMidpoint(data.outputs.monthly.fixed[9]["H(i)_d"], solargisData[index + 1].outputs.monthly.fixed[9]["H(i)_d"]),
                  "H(i)_m": getMidpoint(data.outputs.monthly.fixed[9]["H(i)_m"], solargisData[index + 1].outputs.monthly.fixed[9]["H(i)_m"]),
                  SD_m: getMidpoint(data.outputs.monthly.fixed[9].SD_m, solargisData[index + 1].outputs.monthly.fixed[9].SD_m),
                  month: data.outputs.monthly.fixed[9].month
                },
                {
                  E_d: getMidpoint(data.outputs.monthly.fixed[10].E_d, solargisData[index + 1].outputs.monthly.fixed[10].E_d),
                  E_m: getMidpoint(data.outputs.monthly.fixed[10].E_m, solargisData[index + 1].outputs.monthly.fixed[10].E_m),
                  "H(i)_d": getMidpoint(data.outputs.monthly.fixed[10]["H(i)_d"], solargisData[index + 1].outputs.monthly.fixed[10]["H(i)_d"]),
                  "H(i)_m": getMidpoint(data.outputs.monthly.fixed[10]["H(i)_m"], solargisData[index + 1].outputs.monthly.fixed[10]["H(i)_m"]),
                  SD_m: getMidpoint(data.outputs.monthly.fixed[10].SD_m, solargisData[index + 1].outputs.monthly.fixed[10].SD_m),
                  month: data.outputs.monthly.fixed[10].month
                },
                {
                  E_d: getMidpoint(data.outputs.monthly.fixed[11].E_d, solargisData[index + 1].outputs.monthly.fixed[11].E_d),
                  E_m: getMidpoint(data.outputs.monthly.fixed[11].E_m, solargisData[index + 1].outputs.monthly.fixed[11].E_m),
                  "H(i)_d": getMidpoint(data.outputs.monthly.fixed[11]["H(i)_d"], solargisData[index + 1].outputs.monthly.fixed[11]["H(i)_d"]),
                  "H(i)_m": getMidpoint(data.outputs.monthly.fixed[11]["H(i)_m"], solargisData[index + 1].outputs.monthly.fixed[11]["H(i)_m"]),
                  SD_m: getMidpoint(data.outputs.monthly.fixed[11].SD_m, solargisData[index + 1].outputs.monthly.fixed[11].SD_m),
                  month: data.outputs.monthly.fixed[11].month
                }
              ]
            },
            totals: {
              fixed: {
                E_d: getMidpoint(data.outputs.totals.fixed.E_d, solargisData[index + 1].outputs.totals.fixed.E_d),
                E_m: getMidpoint(data.outputs.totals.fixed.E_m, solargisData[index + 1].outputs.totals.fixed.E_m),
                E_y: getMidpoint(data.outputs.totals.fixed.E_y, solargisData[index + 1].outputs.totals.fixed.E_y),
                "H(i)_d": getMidpoint(data.outputs.totals.fixed["H(i)_d"], solargisData[index + 1].outputs.totals.fixed["H(i)_d"]),
                "H(i)_m": getMidpoint(data.outputs.totals.fixed["H(i)_m"], solargisData[index + 1].outputs.totals.fixed["H(i)_m"]),
                "H(i)_y": getMidpoint(data.outputs.totals.fixed["H(i)_y"], solargisData[index + 1].outputs.totals.fixed["H(i)_y"]),
                SD_m: getMidpoint(data.outputs.totals.fixed.SD_m, solargisData[index + 1].outputs.totals.fixed.SD_m),
                SD_y: getMidpoint(data.outputs.totals.fixed.SD_y, solargisData[index + 1].outputs.totals.fixed.SD_y),
                l_aoi: getMidpoint(data.outputs.totals.fixed.l_aoi, solargisData[index + 1].outputs.totals.fixed.l_aoi),
                l_spec: data.outputs.totals.fixed.l_spec,
                l_tq: getMidpoint(data.outputs.totals.fixed.l_tq, solargisData[index + 1].outputs.totals.fixed.l_tq),
                l_total: getMidpoint(data.outputs.totals.fixed.l_total, solargisData[index + 1].outputs.totals.fixed.l_total)
              }
            }
          }
        }
        finalSolargisData.push(newData);
      }else {
        finalSolargisData.push(data);
      }
    }
  })

  return finalSolargisData;
  
}
