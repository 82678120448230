import { Battery, Car, Home, Power, Settings, Sun, Wrench } from "lucide-react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useIsComponentAdded, useNumberFormat } from "src/hooks";

import { BatteryButtonIcon } from "src/assets/svgs";
import { ProductWithPrice } from "src/db-types";
import { getAverageCapacity, getAverageMaxCapacity, getCheapestProduct } from "src/helpers";
import { autoStringAssignment } from "src/utils";

import { ReactComponent as Edit } from "../assets/EditIcon.svg";
import { ReactComponent as PowerButton } from "../assets/PowerButton.svg";
import { ReactComponent as Solarpanel } from "../assets/Solarpanel.svg";

import SelectedConfiguration from "./SelectedConfiguration";
import SolarConfiguration from "./SelectedConfiguration";

import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";


const ArrowIcon = ({ open, setOpen }: { open: boolean; setOpen: (_: boolean) => void }) => (
  <img
    src={
      open
        ? "/images/application_step_3/arrowLeftWithoutBorder.svg"
        : "/images/application_step_3/arrowDown.svg"
    }
    className="mb-1 hover:cursor-pointer"
    onClick={() => setOpen(!open)}
    style={{ zIndex: "99999", position: "absolute", top: "34px", right: "-13px" }}
    alt=""
  />
);

const menuItems = [
  {
    label: "Overview",
    isActive: false,
    icon: <Power className="w-[18px] h-[18px]" />,
    isDisable: true
  },
  {
    label: "Modules",
    isActive: false,
    icon: <Sun className="w-[18px] h-[18px]" />,
    isDisable: false
  },
  {
    label: "Inverter",
    isActive: true,
    icon: <Settings className="w-[18px] h-[18px]" />,
    isDisable: true
  },
  {
    label: "Battery",
    isActive: false,
    icon: <Battery className="w-[18px] h-[18px]" />,
    isDisable: true
  },
  {
    label: "Car",
    isActive: false,
    icon: <Car className="w-[18px] h-[18px]" />,
    isDisable: true
  },
  {
    label: "Heatpump",
    isActive: false,
    icon: <Home className="w-[18px] h-[18px]" />,
    isDisable: true
  },
];

const SystemConfiguration = ({solardata, documentRef,   cablingLoops, getCableOnInit, setCablingLoops, activeGridChanged, setSolarDataReload}: {
  solardata: any[];
  getCableOnInit: (panelGrids: [], cableGroups: any[]) => void;
  documentRef: any,
  cablingLoops: any[],
  setCablingLoops: (_: any[]) => void;
  activeGridChanged: any;
  setSolarDataReload: any;
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState(menuItems);
  const filteredSolarPanels = useSelector(ProductSelectors.getFilteredSolarPanels);
  const filteredInterters = useSelector(ProductSelectors.getFilteredInverters);
  const filteredBatteries = useSelector(ProductSelectors.getFilteredBatteries);
  const { quantity } = useSelector(ProjectSelectors.getSolarPanel);
  const solarPanelProduct = useSelector(ProjectSelectors.getSolarPanel);
  const inverterProduct = useSelector(ProjectSelectors.getInverter);
  const batterProduct = useSelector(ProjectSelectors.getBattery);
  const { isBatteryAdded } = useIsComponentAdded();
  const { formatNumber } = useNumberFormat();

  const { t } = useTranslation();

   const cheapestProduct = useMemo(() => {
      return getCheapestProduct(filteredInterters);
    }, [filteredInterters]);


   const cheapestSolarpanels = useMemo(() => {
    return getCheapestProduct(filteredSolarPanels);
  }, [filteredSolarPanels]);

  const onActiveMenuItem = (label: string) => {
    setMenu((prevMenu) =>
      prevMenu.map((m) => {
        if (m.label === label) {
          return { ...m, isActive: true };
        }
        return { ...m, isActive: false };
      }),
    );
  };
  const capacity = useMemo(() => {
    return getAverageCapacity(filteredSolarPanels);
  }, [filteredSolarPanels]);
  const inverterCap = useMemo(() => {
    return getAverageMaxCapacity(filteredInterters);
  }, [filteredInterters]);

  const batteryCap = useMemo(() => {
    return getAverageMaxCapacity(filteredBatteries);
  }, [filteredBatteries]);

  let systemCapacity = (capacity * quantity) / 1000;
  if (solarPanelProduct?.item) {
    systemCapacity = (Number(solarPanelProduct?.item.capacity) * quantity) / 1000;
  }

  let interterOutput = inverterCap / 1000;
  if (inverterProduct?.item) {
    interterOutput = Number(inverterProduct?.item.applicableMaxCapacity) / 1000;
  }

  let batteryOutput = batteryCap / 1000;
  if (batterProduct?.item) {
    batteryOutput = Number(batterProduct?.item.applicableMaxCapacity) / 1000;
  }

  const onClickOfAutoStrings = (): boolean => {
    let isError: boolean = false;
    let numberOfMpptCount = cheapestProduct?.numberOfMPPTrackers;
    if(inverterProduct?.item) {
      numberOfMpptCount = inverterProduct.item.numberOfMPPTrackers;
    } 
    if(!solardata.length) {
      isError = true;
      toast.warning(t("Please Draw one panel Grid!!"))
      return isError;
    }
    if(!numberOfMpptCount) {
      isError = true;
      toast.warning(t("Please include the number of MPPTs for the selected inverter."))
      return isError;
    }
    const formatedSolarData = solardata.map((data) => data.details);
    const generatedAutoStrings = autoStringAssignment(formatedSolarData, numberOfMpptCount);
    getCableOnInit(documentRef.current.panelGrids, generatedAutoStrings);
    return isError;
  };

  const selectedProducts = useMemo(() => ({
    inverter : inverterProduct?.item ? inverterProduct.item :  cheapestProduct,
    interterOutput,
    solarPanel: solarPanelProduct?.item ? solarPanelProduct.item : cheapestSolarpanels
  }),[cheapestProduct, cheapestSolarpanels, interterOutput, inverterProduct, solarPanelProduct])

 

  console.log({ cheapestSolarpanels, cheapestProduct })

  return (
    <div className="bg-white rounded-md relative z-[1] mt-2">
      <div className="p-2">
      <div className="flex items-center gap-2 mb-4">
        <h1 className="text-[#2B3674] text-2xl font-semibold">{t("Configuration name")}</h1>
        <button className="text-[#2B3674] hover:text-[#1B254B] transition-colors">
          <Edit className="w-5 h-5" />
          <span className="sr-only">Edit configuration name</span>
        </button>
      </div>

      <div className="flex flex-wrap items-center gap-6">
        <div className="flex items-center gap-3">
          <Solarpanel className="w-[28px] h-[28px]" />
          <div>
            <span className="text-2xl font-semibold text-[#2B3674]">
              {formatNumber(systemCapacity < 1 ? systemCapacity : Math.round(systemCapacity))}
            </span>
            <span className="text-[#2B3674] ml-1">kWp</span>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <PowerButton className="h-[28px] w-[28px]" />
          <div>
            <span className="text-2xl font-semibold text-[#2B3674]">{interterOutput}</span>
            <span className="text-[#2B3674] ml-1">kWh</span>
          </div>
        </div>
        {isBatteryAdded && (
          <div className="flex items-center gap-3">
            <BatteryButtonIcon className="w-[28px] h-[28px]" />
            <div>
              <span className="text-2xl font-semibold text-[#2B3674]">{batteryOutput}</span>
              <span className="text-[#2B3674] ml-1">kWh</span>
            </div>
          </div>
        )}
      </div>
      </div>
      <ArrowIcon open={open} setOpen={setOpen} />
      {open && <SolarConfiguration 
        cablingLoops={cablingLoops} 
        setCablingLoops={setCablingLoops} 
        selectedProducts={selectedProducts} 
        onClickOfAutoStrings={onClickOfAutoStrings} 
        menu={menu}
        onActiveMenuItem={onActiveMenuItem} 
        activeGridChanged={activeGridChanged}
        documentRef={documentRef}
        setSolarDataReload={setSolarDataReload} />}
    </div>
  );
};

export default SystemConfiguration;
