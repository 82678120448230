import { useTranslation } from "react-i18next";

import { ProductDB, ProductWithPrice } from "src/db-types";

import InverterSelection from "./configuration/InverterSelection";
import ModuleSelection from "./configuration/ModuleSelection";

import { FilterableBaseComponent } from "src/redux/project/types/components";

interface SolarConfigurationType {
  menu: Array<{
    label: string;
    isActive: boolean;
    isDisable: boolean;
    icon: JSX.Element;
  }>;
  onActiveMenuItem: (label: string) => void;
  onClickOfAutoStrings: () => boolean;
  selectedProducts: {
    inverter: ProductDB | null;
    interterOutput: number;
    solarPanel: ProductDB | null ;
  };
  cablingLoops: any[];
  setCablingLoops: (_: any[]) => void;
  activeGridChanged: any;
  documentRef: any;
  setSolarDataReload: any;
}

export default function SolarConfiguration({
  menu,
  setCablingLoops,
  cablingLoops,
  selectedProducts,
  onActiveMenuItem,
  onClickOfAutoStrings,
  activeGridChanged,
  documentRef,
  setSolarDataReload
}: SolarConfigurationType): JSX.Element {
  const overviewItem = menu.find((item) => item.label === "Overview");
  const modulesItem = menu.find((item) => item.label === "Modules");
  const inverterItem = menu.find((item) => item.label === "Inverter");
  const batteryItem = menu.find((item) => item.label === "Battery");
  const carItem = menu.find((item) => item.label === "Car");
  const heatpumpItem = menu.find((item) => item.label === "Heatpump");

  return (
    <div className="flex bg-gray-50">
      <div className="w-[91px] bg-[#EFEFEF] shadow-sm flex flex-col items-center py-6 gap-4">
        {menu.map((item) => (
          <NavItem
            onClick={onActiveMenuItem}
            icon={<>{item.icon}</>}
            key={item.label}
            label={item.label}
            active={item.isActive}
            isDisable={item.isDisable}
          />
        ))}
      </div>
      {modulesItem?.isActive && (
        <ModuleSelection
          activeGridChanged={activeGridChanged}
          documentRef={documentRef}
          setSolarDataReload={setSolarDataReload}
        />
      )}
      {inverterItem?.isActive && (
        <InverterSelection
          cablingLoops={cablingLoops}
          setCablingLoops={setCablingLoops}
          selectedProducts={selectedProducts}
          onClickOfAutoStrings={onClickOfAutoStrings}
        />
      )}
    </div>
  );
}

function NavItem({
  icon,
  label,
  active,
  onClick,
  isDisable,
}: {
  icon: React.ReactNode;
  label: string;
  active?: boolean;
  onClick: (label: string) => void;
  isDisable: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <div
      className={`flex flex-col items-center gap-1 ${active ? "bg-white" : ""} w-full pt-1 pb-1 ${
        isDisable ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      aria-disabled={isDisable}
    >
      <div
        className={`w-[28px] h-[28px] rounded-full flex items-center justify-center ${"bg-[#FFD66B]"}  ${
          isDisable ? "cursor-not-allowed" : "cursor-pointer"
        }`}
        onClick={() => onClick(label)}
      >
        <div className={"text-[#2B3674]"}>{icon}</div>
      </div>
      <span className="text-xs text-[#2B3674]">{t(label)}</span>
    </div>
  );
}
